import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import axios from "../../utils/api-client";

function isInternelEmail(email: string): boolean {
  try {
    if (typeof email !== "string" || email.trim() === "") {
      throw new Error("Invalid email input");
    }

    const lowercaseEmail = email.toLowerCase();
    return (
      lowercaseEmail.endsWith("@gigandtake.com") ||
      lowercaseEmail.endsWith("@wilya.com")
    );
  } catch (error) {
    console.error("Error in isInternalEmail:", error);
    return false;
  }
}

interface UserData {
  username: string;
  userRoles: string[];
  userRole: string | null;
  id: string | null;
  firstName: string;
  lastName: string;
  defaultDurationHrs: number;
  minDurationHrs: number;
  maxDurationHrs: number;
  showCrossSkilling: boolean;
  skillPriorityEnabled: boolean;
  useObsoleteSkills: boolean;
  customizedBrandingEnabled: boolean;
  skillExpiryEnabled: boolean;
  certificationsEnabled: boolean;
  skillInsightsEnabled: boolean;
  defaultUserView: string;
  showHeatMap: boolean;
  showWorkerProfile: boolean;
  showJobExpiry: boolean;
  fullTimeSchedule: boolean;
  showShiftSwap: boolean;
  restrictFlexHours: boolean;
  company: string;
  companyId: number | null;
  attendanceManagement: boolean;
  swapAndCancelShift: boolean;
  defaultShiftSkillLevel: number;
  shiftBySkills: boolean;
  assignShiftToWorkerEnabled: boolean;
  assignShiftToWorkerHrsBeforeShift: number;
}

export interface UserState {
  userData: UserData;
  authenticated: boolean;
  login: {
    status: "idle" | "pending" | "fulfilled" | "error";
    errorMessage: string;
    maxFailedAttempts?: number;
    failedAttempt?: number;
    isLocked?: boolean;
  };
  verifySSO: {
    status: "idle" | "pending" | "fulfilled" | "error";
    errorMessage: string;
    isSSOEnabled?: boolean;
    ssoLoginUrl?: string;
  };
  authenticateSSO: {
    status: "idle" | "pending" | "fulfilled" | "error";
    errorMessage: string;
  };
  forgotPassword: {
    status: "idle" | "pending" | "fulfilled" | "error";
    errorMessage: string;
  };
  resetPassword: {
    status: "idle" | "pending" | "fulfilled" | "error";
    errorMessage: string;
  };
  customLogo: any[]; // Define the appropriate type for customLogo
  getCustomLogo: {
    status: "idle" | "pending" | "fulfilled" | "error";
    errorMessage: string;
  };
}

export const loginUser = createAsyncThunk(
  "user/login",
  async (
    { username, password }: { username: string; password: string },
    thunkAPI
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/Users/authenticate`,
        {
          username,
          password,
        }
      );
      if (response.status === 200) {
        const data = response.data;
        localStorage.setItem("token", data.token);
        localStorage.setItem("refreshToken", data.refreshToken);
        return thunkAPI.fulfillWithValue(data);
      } else {
        return thunkAPI.rejectWithValue({
          message: "An error occurred",
          maxFailedAttempts: 0,
          failedAttempt: 0,
          isLocked: false,
        });
      }
    } catch (e: any) {
      const defaultData = {
        message: "An error occurred",
        maxFailedAttempts: 0,
        failedAttempt: 0,
        isLocked: false,
      };
      const response = Object.prototype.hasOwnProperty.call(e, "response")
        ? e.response
        : { data: defaultData };
      const data = Object.prototype.hasOwnProperty.call(response, "data")
        ? response.data
        : defaultData;
      if (Object.prototype.hasOwnProperty.call(data, "message")) {
        defaultData.message = data.message;
      }
      if (
        Object.prototype.hasOwnProperty.call(data, "maxFailedAttempts") &&
        Object.prototype.hasOwnProperty.call(data, "failedAttempt") &&
        Object.prototype.hasOwnProperty.call(data, "isLocked")
      ) {
        return thunkAPI.rejectWithValue({
          message: e.response.data.message,
          maxFailedAttempts: e.response.data.maxFailedAttempts,
          failedAttempt: e.response.data.failedAttempt,
          isLocked: e.response.data.isLocked,
        });
      } else {
        return thunkAPI.rejectWithValue(defaultData);
      }
    }
  }
);

export const verifySSO = createAsyncThunk(
  "user/verifySSO",
  async ({ username }: { username: string }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/Users/verify`,
        {
          username,
          appUrl: process.env.REACT_APP_URL,
        }
      );
      if (response.status === 200) {
        const data = response.data;
        return thunkAPI.fulfillWithValue(data);
      } else {
        return thunkAPI.rejectWithValue("An error occurred");
      }
    } catch (e: any) {
      const defaultData = {
        message: "An error occurred",
        maxFailedAttempts: 0,
        failedAttempt: 0,
        isLocked: false,
      };
      const response = Object.prototype.hasOwnProperty.call(e, "response")
        ? e.response
        : { data: defaultData };
      const data = Object.prototype.hasOwnProperty.call(response, "data")
        ? response.data
        : defaultData;
      if (Object.prototype.hasOwnProperty.call(data, "message")) {
        defaultData.message = data.message;
      }
      if (
        Object.prototype.hasOwnProperty.call(data, "maxFailedAttempts") &&
        Object.prototype.hasOwnProperty.call(data, "failedAttempt") &&
        Object.prototype.hasOwnProperty.call(data, "isLocked")
      ) {
        return thunkAPI.rejectWithValue({
          message: e.response.data.message,
          maxFailedAttempts: e.response.data.maxFailedAttempts,
          failedAttempt: e.response.data.failedAttempt,
          isLocked: e.response.data.isLocked,
        });
      } else {
        return thunkAPI.rejectWithValue(defaultData);
      }
    }
  }
);

export const authenticateSSO = createAsyncThunk(
  "user/authenticateSSO",
  async (data: any, thunkAPI) => {
    try {
      const params = { ...data };
      params.appUrl = process.env.REACT_APP_URL;
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/Users/authenticateSSO`,
        params
      );
      if (response.status === 200) {
        const data = response.data;
        localStorage.setItem("token", data.token);
        localStorage.setItem("refreshToken", data.refreshToken);
        return thunkAPI.fulfillWithValue(data);
      } else {
        return thunkAPI.rejectWithValue("An error occurred");
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (payload: { email: string }, { fulfillWithValue, rejectWithValue }) => {
    let { email } = payload;
    email = email.toString();
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_END_POINT}/Login/Reset`,
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify(email),
      });

      //  console.log(response);

      if (response.status === 204) {
        //console.log(response);
        return fulfillWithValue("fulfilled");
      } else return rejectWithValue(response.data);
    } catch (error: unknown) {
      // Type casting the error to AxiosError type
      const axiosError = error as AxiosError;

      if (axiosError.response && axiosError.response.status === 429) {
        // Handle 429 error by passing the message from the response
        return rejectWithValue(
          axiosError.response.data?.message ||
            "Too many requests. Please try again later."
        );
      } else {
        // Handle any other errors without passing a specific message
        return rejectWithValue(
          "We're currently experiencing technical difficulties sending an email to the provided address. If you believe this is an error or need further assistance, please reach out to our support team. We apologize for any inconvenience and appreciate your patience. Please try again later."
        );
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (
    payload: {
      d: string | null;
      token: string | null;
      newPassword: string;
      confirmPassword: string;
    },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const { d, token, newPassword } = payload;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/Login/Verify`,
        {
          d,
          token,
          password: newPassword,
        }
      );

      if (response.status === 204) {
        return fulfillWithValue("successful");
      } else return rejectWithValue(response.data);
    } catch (error: any) {
      console.error("Error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCustomLogo = createAsyncThunk(
  "user/getCustomLogo",
  async (payload, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const companyId = (getState() as any).user?.userData.companyId;
      const response = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/Companies/Branding?CompanyId=${companyId}`
      );
      if (response.status === 200) {
        return fulfillWithValue(response.data);
      } else return rejectWithValue(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: {
      username: "",
      userRoles: [],
      userRole: null,
      id: null,
      firstName: "",
      lastName: "",
      defaultDurationHrs: 2,
      minDurationHrs: 1,
      maxDurationHrs: 8,
      showCrossSkilling: false,
      skillPriorityEnabled: false,
      useObsoleteSkills: false,
      customizedBrandingEnabled: false,
      skillExpiryEnabled: false,
      certificationsEnabled: false,
      skillInsightsEnabled: false,
      defaultUserView: "FlexScheduler",
      showHeatMap: false,
      showWorkerProfile: false,
      showJobExpiry: false,
      fullTimeSchedule: false,
      showShiftSwap: false,
      restrictFlexHours: false,
      company: "",
      companyId: null,
      attendanceManagement: false,
      swapAndCancelShift: false,
      defaultShiftSkillLevel: 0,
      shiftBySkills: false,
      assignShiftToWorkerEnabled: false,
      assignShiftToWorkerHrsBeforeShift: 8,
    },
    authenticated: false,
    login: {
      status: "idle",
      errorMessage: "",
    },
    verifySSO: {
      status: "idle",
      errorMessage: "",
      isSSOEnabled: undefined,
      ssoLoginUrl: undefined,
    },
    authenticateSSO: {
      status: "idle",
      errorMessage: "",
    },
    forgotPassword: {
      status: "idle",
      errorMessage: "",
    },
    resetPassword: {
      status: "idle",
      errorMessage: "",
    },
    customLogo: [],
    getCustomLogo: {
      status: "idle",
      errorMessage: "",
    },
  } as UserState, // Initial state with correct types
  reducers: {
    setUserId: (state, action: PayloadAction<number>) => {
      state.userData.id = action.payload.toString();
    },
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
    setUser: (state, action) => {
      state.userData.id = action.payload.id;
      state.userData.userRoles = action.payload.userRoles ?? [];
      state.userData.showHeatMap = action.payload.showHeatMap;
      state.userData.showJobExpiry = action.payload.showJobExpiry;
      state.userData.showWorkerProfile = action.payload.showWorkerProfile;
      state.userData.showCrossSkilling = action.payload.showCrossSkilling;

      state.userData.skillPriorityEnabled = action.payload.skillPriorityEnabled;
      state.userData.useObsoleteSkills = action.payload.useObsoleteSkills;

      state.userData.customizedBrandingEnabled =
        action.payload.customizedBrandingEnabled;
      state.userData.skillExpiryEnabled = action.payload.skillExpiryEnabled;
      state.userData.certificationsEnabled =
        action.payload.certificationsEnabled;
      state.userData.skillInsightsEnabled = action.payload.skillInsightsEnabled;
      state.userData.defaultUserView = action.payload.defaultUserView;
      state.userData.fullTimeSchedule = action.payload.fullTimeSchedule;
      state.userData.showShiftSwap = action.payload.showShiftSwap;
      state.userData.restrictFlexHours = action.payload.restrictFlexHours;
      state.authenticated = true;
      state.userData.defaultDurationHrs = action.payload.defaultDurationHrs;
      state.userData.minDurationHrs = action.payload.minDurationHrs;
      state.userData.maxDurationHrs = action.payload.maxDurationHrs;
      state.userData.company = action.payload.company;
      state.userData.firstName = action.payload.firstName;
      state.userData.lastName = action.payload.lastName;
      state.userData.username = action.payload.username;
      state.userData.companyId = action.payload.companyId;
      state.userData.attendanceManagement = action.payload["attendMgmt"];
      state.userData.swapAndCancelShift = action.payload["swapAndCancelShift"];
      state.userData.defaultShiftSkillLevel = Number(
        action.payload.shiftBySkillsMinSkillLevel
      );
      state.userData.shiftBySkills = action.payload.shiftBySkillsEnabled;
      state.userData.assignShiftToWorkerEnabled =
        action.payload.assignShiftToWorkerEnabled;
      state.userData.assignShiftToWorkerHrsBeforeShift =
        action.payload.assignShiftToWorkerHrsBeforeShift;

      // HEAP IDENTIFIER USER
      if (
        (process.env.REACT_APP_ENV === "demo" ||
          process.env.REACT_APP_ENV === "prod") &&
        action.payload &&
        (window as any).heap &&
        typeof (window as any).heap.identify === "function"
      ) {
        (window as any).heap.identify(action.payload.id);

        (window as any).heap.addUserProperties({
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          userType: isInternelEmail(action.payload.username)
            ? "internal"
            : "external",
          company: action.payload.company,
          companyId: action.payload.companyId,
          userRole: action.payload.userRoles.find(
            (role: string) => role === "ShiftSupervisor"
          )
            ? "ShiftSupervisor"
            : "ReadOnly",
          isGATAdmin: action.payload.userRoles.find(
            (role: string) => role === "GATAdmin"
          )
            ? "Yes"
            : "No",
        });
      }
    },
    logoutUser: (state) => {
      state.userData.id = "";
      state.userData.userRoles = [];
      state.customLogo = [];
      state.authenticated = false;
      state.login.status = "idle";
      state.login.errorMessage = "";
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
    },
    resetForgotPassword: (state) => {
      state.forgotPassword.status = "idle";
      state.forgotPassword.errorMessage = "";
    },
    resetLoginErrors: (state) => {
      state.login.status = "idle";
      state.login.errorMessage = "";
      state.login.maxFailedAttempts = 5;
      state.login.failedAttempt = 0;
      state.login.isLocked = false;
      state.verifySSO.status = "idle";
      state.verifySSO.errorMessage = "";
      state.verifySSO.isSSOEnabled = undefined;
      state.verifySSO.ssoLoginUrl = undefined;
      state.authenticateSSO.status = "idle";
      state.authenticateSSO.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action: any) => {
      const {
        username,
        userRoles,
        id,
        firstName,
        lastName,
        settings,
        shiftSettings,
        company,
        companyId,
      } = action.payload;
      state.userData.username = username;
      state.userData.userRoles = userRoles;
      state.userData.id = id;
      state.userData.firstName = firstName;
      state.userData.lastName = lastName;
      state.userData.company = company;
      state.userData.companyId = companyId;
      state.userData.showWorkerProfile = settings.showWorkerProfile;
      state.userData.showHeatMap = settings.showHeatMap;
      state.userData.showJobExpiry = settings.showJobExpiry;
      state.userData.showCrossSkilling = settings.showCrossSkilling;

      state.userData.skillPriorityEnabled = settings["skillPriority.Enabled"];
      state.userData.useObsoleteSkills =
        settings["skillPriority.workerScore.useObsoleteSkill"];

      state.userData.customizedBrandingEnabled =
        settings.customizedBrandingEnabled;
      if (!settings.customizedBrandingEnabled) {
        // No extra step require for authentication
        state.authenticated = true;
        state.login.status = "fulfilled";
      }
      state.customLogo = [];
      state.userData.skillExpiryEnabled = settings.skillExpiryEnabled;
      state.userData.certificationsEnabled = settings.certificationsEnabled;
      state.userData.skillInsightsEnabled = settings.skillInsightsEnabled;
      state.userData.fullTimeSchedule = settings.fullTimeSchedule;
      state.userData.showShiftSwap = settings.showShiftSwap;
      state.userData.restrictFlexHours = settings.restrictFlexHours;

      state.userData.attendanceManagement = settings["attendMgmt.enabled"];
      state.userData.swapAndCancelShift =
        settings["ftShift.swapAndCancelEnabled"];

      state.userData.shiftBySkills = settings.shiftBySkillsEnabled;

      state.userData.defaultDurationHrs = shiftSettings.defaultDurationHrs || 2;
      state.userData.minDurationHrs = shiftSettings.minDurationHrs || 1;
      state.userData.maxDurationHrs = shiftSettings.maxDurationHrs || 8;
      state.userData.defaultShiftSkillLevel = Number(
        settings.shiftBySkillsMinSkillLevel
      );
      state.userData.assignShiftToWorkerEnabled =
        settings.assignShiftToWorkerEnabled;
      state.userData.assignShiftToWorkerHrsBeforeShift =
        settings.assignShiftToWorkerHrsBeforeShift;
    });
    builder.addCase(loginUser.pending, (state) => {
      state.login.status = "pending";
    });
    builder.addCase(loginUser.rejected, (state, action: any) => {
      state.login.status = "error";
      state.login.errorMessage =
        action.payload?.message ?? "An error occurred. Please try again.";
      state.login.maxFailedAttempts = action.payload?.maxFailedAttempts ?? 0;
      state.login.failedAttempt = action.payload?.failedAttempt ?? 0;
      state.login.isLocked = action.payload?.isLocked ?? false;
    });
    builder.addCase(verifySSO.fulfilled, (state, action: any) => {
      state.verifySSO.status = "fulfilled";
      state.verifySSO.isSSOEnabled =
        action.payload.isSSOEnabled === null ||
        action.payload.isSSOEnabled === ""
          ? undefined
          : action.payload.isSSOEnabled;
      state.verifySSO.ssoLoginUrl =
        action.payload.ssoLoginUrl === null || action.payload.ssoLoginUrl === ""
          ? undefined
          : action.payload.ssoLoginUrl;
    });
    builder.addCase(verifySSO.pending, (state) => {
      state.verifySSO.status = "pending";
      state.login.status = "idle";
      state.login.errorMessage = "";
      state.verifySSO.errorMessage = "";
      state.authenticateSSO.status = "idle";
      state.authenticateSSO.errorMessage = "";
    });
    builder.addCase(verifySSO.rejected, (state, action: any) => {
      state.verifySSO.status = "error";
      state.verifySSO.errorMessage =
        action.payload?.message ?? "An error occurred. Please try again.";
      state.login.maxFailedAttempts = action.payload?.maxFailedAttempts ?? 0;
      state.login.failedAttempt = action.payload?.failedAttempt ?? 0;
      state.login.isLocked = action.payload?.isLocked ?? false;
    });
    builder.addCase(authenticateSSO.fulfilled, (state, action: any) => {
      const {
        username,
        userRoles,
        id,
        firstName,
        lastName,
        settings,
        shiftSettings,
        company,
        companyId,
        //defaultUserView,
      } = action.payload;
      state.userData.username = username;
      state.userData.userRoles = userRoles;
      state.userData.id = id;
      state.userData.firstName = firstName;
      state.userData.lastName = lastName;
      state.userData.company = company;
      state.userData.companyId = companyId;
      state.userData.showWorkerProfile = settings.showWorkerProfile;
      state.userData.showHeatMap = settings.showHeatMap;
      state.userData.showJobExpiry = settings.showJobExpiry;
      state.userData.showCrossSkilling = settings.showCrossSkilling;

      state.userData.skillPriorityEnabled = settings["skillPriority.Enabled"];
      state.userData.useObsoleteSkills =
        settings["skillPriority.workerScore.useObsoleteSkill"];

      state.userData.customizedBrandingEnabled =
        settings.customizedBrandingEnabled;
      if (!settings.customizedBrandingEnabled) {
        // No extra step require for authentication
        state.authenticated = true;
        state.login.status = "fulfilled";
      }
      state.userData.skillExpiryEnabled = settings.skillExpiryEnabled;
      state.userData.certificationsEnabled = settings.certificationsEnabled;
      state.userData.skillInsightsEnabled = settings.skillInsightsEnabled;
      state.userData.defaultUserView = settings.defaultUserView;
      state.userData.fullTimeSchedule = settings.fullTimeSchedule;
      state.userData.showShiftSwap = settings.showShiftSwap;
      state.userData.restrictFlexHours = settings.restrictFlexHours;
      state.userData.attendanceManagement = settings["attendMgmt.enabled"];
      state.userData.swapAndCancelShift =
        settings["ftShift.swapAndCancelEnabled"];
      state.userData.defaultShiftSkillLevel = Number(
        settings.shiftBySkillsMinSkillLevel
      );
      state.userData.shiftBySkills = settings.shiftBySkillsEnabled;

      state.userData.defaultDurationHrs = shiftSettings.defaultDurationHrs ?? 2;
      state.userData.minDurationHrs = shiftSettings.minDurationHrs ?? 1;
      state.userData.maxDurationHrs = shiftSettings.maxDurationHrs ?? 8;
      state.userData.assignShiftToWorkerEnabled =
        settings.assignShiftToWorkerEnabled;
      state.userData.assignShiftToWorkerHrsBeforeShift =
        settings.assignShiftToWorkerHrsBeforeShift;
      state.authenticateSSO.status = "fulfilled";

      // HEAP IDENTIFIER USER
      if (
        (process.env.REACT_APP_ENV === "demo" ||
          process.env.REACT_APP_ENV === "prod") &&
        action.payload &&
        (window as any).heap &&
        typeof (window as any).heap.identify === "function"
      ) {
        (window as any).heap.identify(id);

        (window as any).heap.addUserProperties({
          firstName: firstName,
          lastName: lastName,
          userType: isInternelEmail(username) ? "internal" : "external",
          company: company,
          companyId: companyId,
          userRole: userRoles.find((role: string) => role === "ShiftSupervisor")
            ? "ShiftSupervisor"
            : "ReadOnly",
          isGATAdmin: userRoles.find((role: string) => role === "GATAdmin")
            ? "Yes"
            : "No",
        });
      }
    });
    builder.addCase(authenticateSSO.pending, (state) => {
      state.authenticateSSO.status = "pending";
      state.login.status = "idle";
      state.login.errorMessage = "";
      state.verifySSO.status = "idle";
      state.verifySSO.errorMessage = "";
    });
    builder.addCase(authenticateSSO.rejected, (state, action: any) => {
      state.authenticateSSO.status = "error";
      state.authenticateSSO.errorMessage =
        action.payload?.message ?? "An error occurred. Please try again.";
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.forgotPassword.status = "fulfilled";
    });
    builder.addCase(forgotPassword.pending, (state) => {
      state.forgotPassword.status = "pending";
    });
    builder.addCase(forgotPassword.rejected, (state, action: any) => {
      state.forgotPassword.status = "error";
      state.forgotPassword.errorMessage =
        action.payload ?? "An error occurred. Please try again.";
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.resetPassword.status = "fulfilled";
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.resetPassword.status = "pending";
    });
    builder.addCase(resetPassword.rejected, (state, action: any) => {
      state.resetPassword.status = "error";
      // console.log(action.payload);
      state.resetPassword.errorMessage =
        action.payload?.message ?? "An error occurred. Please try again.";
    });
    builder.addCase(getCustomLogo.fulfilled, (state, action: any) => {
      state.getCustomLogo.status = "fulfilled";
      state.login.status = "fulfilled";
      state.customLogo = action.payload ?? [];
    });
    builder.addCase(getCustomLogo.pending, (state) => {
      state.getCustomLogo.status = "pending";
    });
    builder.addCase(getCustomLogo.rejected, (state, action: any) => {
      state.getCustomLogo.status = "error";
      state.customLogo = [];
      // console.log(action.payload);
      state.getCustomLogo.errorMessage =
        action.payload?.message ?? "An error occurred. Please try again.";
    });
  },
});

export const userSelector = (state: { user: UserState }) => state.user;

export const {
  setUserId,
  setUser,
  setAuthenticated,
  logoutUser,
  resetForgotPassword,
  resetLoginErrors,
} = userSlice.actions;

export default userSlice.reducer;
