import { SxProps, Theme } from "@mui/material/styles";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid-pro-6";
import { GridInitialStatePro } from "@mui/x-data-grid-pro-6/models/gridStatePro";
import * as React from "react";

interface SortedDataGridPro6Props
  extends Omit<DataGridProProps, "columns" | "sortModel"> {
  columns: GridColDef[];
  rows: any[];
  defaultSortField?: string;
  initialState?: GridInitialStatePro;
  sx?: SxProps<Theme>;
}

const SortedDataGridPro6: React.FC<SortedDataGridPro6Props> = ({
  columns,
  rows,
  defaultSortField = "name",
  initialState,
  sx,
  ...otherProps
}) => {
  const defaultSx: SxProps<Theme> = {
    "& .MuiDataGrid-iconButtonContainer .MuiBadge-root": {
      display: "none", // Hide the 99+ badge
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none !important",
    },
  };

  // Define default ascending sort model
  const defaultSortModel: GridSortModel = columns.map((column) => ({
    field: defaultSortField,
    sort: "asc",
  }));

  const defaultInitialState: GridInitialStatePro = {
    sorting: {
      sortModel: defaultSortModel,
    },
  };

  // Add the sortingOrder for each column to enforce only asc and desc
  const updatedColumns: GridColDef[] = columns.map((column) => ({
    ...column,
    sortingOrder: ["asc", "desc"], // Disable the default 'unsorted' state
    disableColumnMenu: true,
  }));

  const mergedInitialState: GridInitialStatePro = {
    ...defaultInitialState,
    ...initialState,
  };

  const mergedSx: SxProps<Theme> = {
    ...defaultSx,
    ...sx, // User's sx will override defaultSx if provided
  };

  return (
    <DataGridPro
      rows={rows}
      sx={mergedSx}
      columns={updatedColumns}
      initialState={mergedInitialState}
      {...otherProps}
    />
  );
};

export default SortedDataGridPro6;
